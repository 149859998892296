import { useState } from 'react';
import * as api from '../../../api';
import {
  IPreHeaderRole,
  IPreHeaderRoleMessage,
  IPreHeaderRoleMessageWhiteList,
  PreHeaderRoleListKey,
  WHITE_LIST_TYPES,
} from '../models/preheader.models';
import { feedback } from '../../../utils';

const usePreHeaderCheckWhiteList = ({
  message,
  handleChange,
  role,
}: {
  message: IPreHeaderRoleMessage;
  handleChange: (
    roleKey: PreHeaderRoleListKey,
    active: boolean,
    role: IPreHeaderRole,
  ) => void;
  role: IPreHeaderRole;
}) => {
  const [whiteListItems, setWhiteListItems] = useState<
    IPreHeaderRoleMessageWhiteList[]
  >(message.preHeaderWhiteList);

  const [loading, setLoading] = useState(false);

  const createWhiteList = (e: any, item: String) => {
    setLoading(true);
    if (e.target.checked) {
      api
        .postDataCall({
          dataPath: '/pre-header-white-list',
          data: {
            preHeaderMessageId: message.id,
            type: WHITE_LIST_TYPES.URL,
            matchType: 'exact',
            value: item,
          },
        })
        .then((response) => {
          handleChange(role.customerRole, role.active, {
            ...role,
            preHeaderMessages: (role.preHeaderMessages ?? []).map(
              (m: IPreHeaderRoleMessage) => {
                if (m.id === message.id) {
                  message.preHeaderWhiteList = [
                    ...whiteListItems,
                    response.data,
                  ];
                }
                return m;
              },
            ),
          });

          setWhiteListItems([...whiteListItems, response.data]);
        })
        .finally(() => {
          setLoading(false);
        })
        .catch((error) => {
          feedback(error.message);
        });
    } else {
      const id = whiteListItems.find(
        (whiteListItem) => whiteListItem.value === item,
      )?.id;

      api
        .deleteCall({
          dataPath: `/pre-header-white-list/${id}`,
        })
        .then(() => {
          handleChange(role.customerRole, role.active, {
            ...role,
            preHeaderMessages: (role.preHeaderMessages ?? []).map(
              (m: IPreHeaderRoleMessage) => {
                if (m.id === message.id) {
                  message.preHeaderWhiteList = [
                    ...whiteListItems.filter((item) => item.id !== id),
                  ];
                }
                return m;
              },
            ),
          });
          setWhiteListItems(whiteListItems.filter((item) => item.id !== id));
          console.log('delete', whiteListItems);
        })
        .catch((error) => {
          feedback(error.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return { whiteListItems, loading, createWhiteList };
};

export default usePreHeaderCheckWhiteList;
