import { MESSAGE_MAX_LENGTH } from '../../constants';
import {
  IPreHeaderRole,
  IPreHeaderRoleMessage,
  IPreHeaderRoles,
} from '../../models/preheader.models';
import { removeHtmlTags } from '../../utils/cleanHtmlTags';

export const validatePreHeaderFields = (fields: any) => {
  let errorControl: boolean = true;
  let errorMessage: string = '';

  const invalidMessages = validateMessagesLength(fields);
  if (invalidMessages.length) {
    return {
      errorControl: false,
      errorMessage: invalidMessages[0].message,
    };
  }
  const rolesWithoutMessages = validateAtLeastOneMessageActive(fields);
  if (!rolesWithoutMessages.isValid) {
    return {
      errorControl: false,
      errorMessage: 'At least one message must be active for each role active.',
    };
  }

  const isAValidDateRange = validateDatesRanges(fields);
  if (!isAValidDateRange) {
    return {
      errorControl: false,
      errorMessage: 'The final date must be greater than the initial date.',
    };
  }

  const whiteListErrors = validateWhiteList(fields);

  if (whiteListErrors.length) {
    return {
      errorControl: false,
      errorMessage: whiteListErrors[0].message,
    };
  }

  return {
    errorControl,
    errorMessage,
  };
};

export const validateWhiteList = (field: IPreHeaderRoles) => {
  const whiteListErrors: any = [];

  field.preHeaderRoles?.forEach((role: IPreHeaderRole) => {
    role.preHeaderMessages?.forEach((message: IPreHeaderRoleMessage) => {
      if (!message.preHeaderWhiteList.length && message.active && role.active) {
        whiteListErrors.push({
          preHeaderRoleId: role.id,
          messageId: message.id,
          message: `Message [${message.id}] must have at least one white list item`,
        });
      }
    });
  });
  return whiteListErrors;
};

export const messageErrorLength = (message: string) => {
  const cleanText = removeHtmlTags(message);
  if (!cleanText.length) {
    return {
      error: true,
      message: 'Message cannot be empty',
    };
  }

  return {
    error: cleanText.length > MESSAGE_MAX_LENGTH,
    message: `Message exceeds the maximum length of ${MESSAGE_MAX_LENGTH} characters`,
  };
};

export const isValidUrl = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (_) {
    return false;
  }
};

const validateMessagesLength = (data: IPreHeaderRoles) => {
  const invalidMessages: any = [];

  data.preHeaderRoles?.forEach((role: IPreHeaderRole) => {
    role.preHeaderMessages?.forEach((message: IPreHeaderRoleMessage) => {
      const messageErrors = messageErrorLength(message.message);

      if (messageErrors.error && message.active) {
        invalidMessages.push({
          preHeaderRoleId: role.id,
          messageId: message.id,
          message: `[${message.id}]: ${messageErrors.message}`,
          length: message.message.length,
        });
      }
    });
  });

  return invalidMessages;
};

const validateAtLeastOneMessageActive = (data: IPreHeaderRoles) => {
  for (const role of data.preHeaderRoles || []) {
    const activeMessagesCount = role.preHeaderMessages?.filter(
      (message: IPreHeaderRoleMessage) => message.active,
    ).length;
    if (role.active && activeMessagesCount === 0) {
      return { isValid: false, roleId: role.id };
    }
  }

  return { isValid: true };
};

export const validateDatesRanges = (data: IPreHeaderRoles): boolean => {
  return !(
    data.initialDate &&
    data.finalDate &&
    new Date(data.finalDate) < new Date(data.initialDate)
  );
};
