import React, { FC } from 'react';
import {
  IPreHeaderRoleMessage,
  PreHeaderRoleListKey,
} from './models/preheader.models';
import Text from 'antd/lib/typography/Text';
import { Icon, IPreHeaderRole } from './models/preheader.models';
import { PreHeaderMessageFields } from './components/preHeaderMessageFields/PreHeaderMessageFields';
import { Switch } from 'antd';

export const WebeatPreHeaderRolesFields: FC<{
  active: boolean;
  preHeaderId?: string;
  parentProps: any;
  role: IPreHeaderRole;
  icons: Icon[];
  handleChange?: (
    roleKey: PreHeaderRoleListKey,
    active: boolean,
    role: IPreHeaderRole,
  ) => void;
}> = ({ role, active, icons, preHeaderId, handleChange = () => {} }) => {

  const handleChangeActive = (value: boolean) => {
    if (handleChange) {
      handleChange(role.customerRole, value, {
        ...role,
        active: value,
        preHeaderMessages: role.preHeaderMessages?.map(
          (m: IPreHeaderRoleMessage) => {
            if (!value) {
              m.active = false;
            }
            return m;
          },
        ),
      });
    }
  };

  return (
    <div className="webeat-preheader-role__panel--content">
      <div
        style={{
          display: 'flex',
          gap: 10,
          alignItems: 'center',
        }}>
        <Switch
          checked={active}
          onChange={(e) => handleChangeActive(e)}
          disabled={false}
        />
        <Text>Active</Text>
      </div>
      <br />
      <div>
        <PreHeaderMessageFields
          preHeaderId={preHeaderId}
          handleChange={handleChange}
          role={role}
          icons={icons}
          messages={role.preHeaderMessages || []}
        />
        <br />
        <br />
      </div>
    </div>
  );
};
