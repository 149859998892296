import React from 'react';
import { pagesConfigurationItems } from '../../../constants';
import { Checkbox } from 'antd';
import usePreHeaderCheckWhiteList from '../../../hooks/usePreHeaderCheckWhiteList';
import {
  IPreHeaderRole,
  IPreHeaderRoleMessage,
  PreHeaderRoleListKey,
} from '../../../models/preheader.models';

const PreHeaderCheckWhiteList = ({
  role,
  message,
  handleChange,
}: {
  role: IPreHeaderRole;
  message: IPreHeaderRoleMessage;
  handleChange: (
    roleKey: PreHeaderRoleListKey,
    active: boolean,
    role: IPreHeaderRole,
  ) => void;
}) => {
  const { whiteListItems, loading, createWhiteList } =
    usePreHeaderCheckWhiteList({ message, role, handleChange });

  return (
    <div
      style={{
        display: 'flex',
        gap: 10,
        flexDirection: 'column',
      }}>
      {Object.keys(pagesConfigurationItems).map((item) => (
        <div>
          <Checkbox
            disabled={loading}
            checked={whiteListItems.some(
              (whiteListItem) =>
                whiteListItem.value ===
                pagesConfigurationItems[
                  item as keyof typeof pagesConfigurationItems
                ],
            )}
            onChange={(e) => {
              createWhiteList(
                e,
                pagesConfigurationItems[
                  item as keyof typeof pagesConfigurationItems
                ],
              );
            }}>
            {item}
          </Checkbox>
        </div>
      ))}
    </div>
  );
};

export default PreHeaderCheckWhiteList;
