import React from 'react';
import config from '../config';
import DashboardInitializer from '../dashboards/DashboardInitializer';

const WebeatPreHeaderDashboard = (props: any) => {
  const { formatMessage } = props.intl;
  const { SMALL, MID } = config.COMPONENT.COLUMN;

  const components = [
    {
      params: {
        type: 'search',
        componentId: 'webeatPreHeaderSearch',
      },
      groups: [
        {
          index: 0,
          title: 'Webeat PreHeader',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: 'Webeat PreHeader',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'name',
                  yPosition: 0,
                  xPosition: 1,
                  mustRender: true,
                  width: 12,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({ id: 'preHeader.name' }),
          key: 'name',
          type: 'text',
          operand: 'like',
          min: 0,
          validationType: 'text',
          length: 100,
          mandatory: false,
          disabled: false,
          globalSearch: true,
        },
        {
          title: formatMessage({ id: 'preHeader.textColor' }),
          key: 'textColor',
          type: 'selector',
          selectorPath: '/design-resources/color',
          multiSelect: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'preHeader.backgroundColor' }),
          key: 'backgroundColor',
          type: 'selector',
          selectorPath: '/design-resources/color',
          multiSelect: false,
          disabled: false,
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
      },
    },
    {
      params: {
        type: 'table',
        componentId: 'webeatPreHeaderList',
        rowKey: 'id',
        panelHeader: 'PreHeader List',
        navigationParams: props.query.params.q || '',
        isMainTable: true,
        tabIndex: -1,
        prettierKey: 'name',
        duplicateRow: {
          title: formatMessage({ id: 'preHeader.duplicate' }),
          description: formatMessage({ id: 'preHeader.duplicateDescription' }),
          key: 'newName',
          sameSite: true,
          webeatSites: true,
          idKey: 'preHeaderId',
          duplicatePath: '/pre-header/duplicate',
        },
      },
      fields: [
        {
          title: formatMessage({ id: 'preHeader.id' }),
          key: 'id',
          position: 0,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: false,
          width: SMALL,
        },
        {
          title: formatMessage({ id: 'preHeader.name' }),
          key: 'name',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: MID,
          defaultSortOrder: 'ascend',
        },

        {
          title: formatMessage({ id: 'preHeader.textColor' }),
          key: 'textColor',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: MID,
        },
        {
          title: formatMessage({ id: 'preHeader.backgroundColor' }),
          key: 'backgroundColor',
          position: 1,
          mustRender: true,
          align: 'left',
          sorter: false,
          visible: true,
          width: MID,
        },
        {
          title: formatMessage({ id: 'preHeader.updatedDate' }),
          key: 'updatedDate',
          position: 4,
          mustRender: true,
          align: 'left',
          sorter: true,
          visible: true,
          width: MID,
        },
        {
          title: formatMessage({ id: 'preHeader.active' }),
          key: 'active',
          render: 'check',
          position: 2,
          mustRender: true,
          align: 'center',
          sorter: true,
          visible: true,
          width: SMALL,
        },
      ],
      settings: {
        size: config.COMPONENT.TABLE.SIZE,
        bordered: config.COMPONENT.TABLE.BORDERED,
        editable: true,
        multiple: false,
        canCreate: true,
        canDelete: true,
        scroll: { x: true },
      },
      pagination: {
        defaultPageSize: 50,
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: config.COMPONENT.TABLE.SHOW_QUICK_JUMPER,
        showSizeChanger: config.COMPONENT.TABLE.SHOW_SIZE_CHANGER,
        showTotal: config.COMPONENT.TABLE.SHOW_TOTAL,
        size: config.COMPONENT.TABLE.SIZE,
      },
    },
    {
      params: {
        type: 'edit',
        componentId: 'webeatPreHeaderEdit',
        panelHeader: formatMessage({ id: 'preHeader.edit' }),
        primaryKey: 'id',
        tabIndex: 0,
        changeActiveItem: {
          type: 'modal',
          text: (params: { old: string; new: string }) =>
            formatMessage({ id: 'preHeader.pre-submit' }, params),
          path: '/pre-header',
          key: 'name',
        },
      },
      groups: [
        {
          index: 0,
          title: '',
          mustRender: true,
          subgroups: [
            {
              index: 0,
              title: '',
              mustRender: true,
              yPosition: 0,
              xPosition: 0,
              width: 24,
              fields: [
                {
                  key: 'id',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'name',
                  yPosition: 0,
                  xPosition: 0,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'textColor',
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'backgroundColor',
                  yPosition: 1,
                  xPosition: 0,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'initialDate',
                  yPosition: 2,
                  xPosition: 0,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'finalDate',
                  yPosition: 2,
                  xPosition: 0,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'active',
                  yPosition: 3,
                  xPosition: 0,
                  mustRender: true,
                  width: 6,
                },
                {
                  key: 'preHeaderRoles',
                  yPosition: 6,
                  xPosition: 0,
                  mustRender: true,
                  width: 24,
                },
              ],
            },
          ],
        },
      ],
      fields: [
        {
          title: formatMessage({ id: 'preHeader.id' }),
          key: 'id',
          type: 'text',
          disabled: true,
        },
        {
          title: formatMessage({ id: 'preHeader.name' }),
          key: 'name',
          type: 'text',
          validationType: 'text',
          disabled: false,
          mandatory: true,
          length: 50,
        },
        {
          title: formatMessage({ id: 'preHeader.textColor' }),
          key: 'textColor',
          type: 'selector',
          selectorPath: '/design-resources/color',
          multiSelect: false,
          value: 'colorCode',
          min: 0,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'preHeader.backgroundColor' }),
          key: 'backgroundColor',
          type: 'selector',
          selectorPath: '/design-resources/color',
          multiSelect: false,
          value: 'colorCode',
          min: 0,
          mandatory: false,
          disabled: false,
        },
        {
          title: formatMessage({ id: 'preHeader.initialDate' }),
          key: 'initialDate',
          type: 'datetime',
          mustRender: true,
          visible: true,
          initialDisabled: true,
          behaviours: [
            [
              {
                key: 'active',
                operand: 'anyValue',
                type: 'disabled',
              },
            ],
          ],
        },
        {
          title: formatMessage({ id: 'preHeader.finalDate' }),
          key: 'finalDate',
          type: 'datetime',
          mustRender: true,
          visible: true,
          initialDisabled: true,
          behaviours: [
            [
              {
                key: 'active',
                operand: 'anyValue',
                type: 'disabled',
              },
            ],
          ],
        },
        {
          title: formatMessage({ id: 'preHeader.active' }),
          key: 'active',
          type: 'switch',
          initialValue: false,
          initialDisabled: true,
          behaviours: [
            [
              {
                key: 'id',
                operand: 'notEmpty',
                type: 'disabled',
              },
            ],
          ],
        },
        {
          title: formatMessage({ id: 'preHeader.roles' }),
          key: 'preHeaderRoles',
          type: 'preHeader-roles',
        },
      ],
      settings: {
        fieldSize: config.COMPONENT.FORM.FIELD_SIZE,
        fieldGutter: config.COMPONENT.FORM.FIELD_MARGIN,
        canEdit: true,
        canCreate: true,
        canDelete: true,
      },
    },
  ];

  const componentId = 'WebeatPreHeaderDashboard';

  return (
    <DashboardInitializer
      dashboardId={componentId}
      components={components}
      {...props}
    />
  );
};

export default WebeatPreHeaderDashboard;
