import React, { useState, PropsWithChildren } from 'react';
import './WebeatCoverVideoUpload.css';
import { getUploadType, UploadType } from './webeatCoverVideo.model';
import PreviewButton from './preview/PreviewButton';
import GenericUrlInput from './inputs/genericUrl/GenericUrlInput';
import S3Input from './inputs/s3/S3Input';
import { TypeSelector } from './typeSelector/TypeSelector';
import { Button } from 'antd';

interface Props {
  disabled?: boolean;
  token: string;
  value?: any | any[];
  fieldName: string;
  handleChange: (value: string | null, fileType: UploadType | null) => void;
  challengeId: string | number;
}

export default function WebeatCoverVideoUpload({
  children,
  disabled,
  handleChange,
  token,
  value,
  fieldName,
  challengeId,
}: PropsWithChildren<Props>) {
  const [selectedUploadType, setSelectedUploadType] =
    useState<UploadType | null>(null);
  const implicitUploadType = getUploadType(value) ?? UploadType.GenericUrl;
  const uploadType = selectedUploadType ?? implicitUploadType;

  const handleInternalChange = (
    value: string | null,
    uploadType: UploadType,
  ) => {
    // We always have to set the selected upload type on an internal change, since
    // when only changing the value, the implicit upload type can differ from the
    // previous implicit upload type, and we don't want it to change.
    setSelectedUploadType(uploadType);
    handleChange(value, uploadType);
  };

  const setNewUploadType = (uploadType: UploadType) => {
    handleInternalChange(null, uploadType);
  };

  const handleS3Change = (value: string | null) => {
    handleInternalChange(value, UploadType.S3);
  };

  const handleGenericUrlChange = (value: string) => {
    handleInternalChange(value, UploadType.GenericUrl);
  };

  const handleRemove = () => {
    handleInternalChange(null, uploadType);
  };

  return (
    <>
      <div className="webeatCoverVideoUpload">
        <TypeSelector
          fieldName={fieldName}
          value={value}
          uploadType={uploadType}
          disabled={disabled}
          handleChange={setNewUploadType}
        />
        {uploadType === UploadType.S3 && (
          <S3Input
            value={value}
            disabled={disabled}
            handleChange={handleS3Change}
            challengeId={challengeId}
            token={token}
            children={children}
          />
        )}
        {uploadType === UploadType.GenericUrl && (
          <GenericUrlInput
            value={value}
            disabled={disabled}
            handleChange={handleGenericUrlChange}
          />
        )}
        <PreviewButton
          url={value}
          uploadType={uploadType}
          disabled={disabled}
        />
        {value && uploadType !== UploadType.GenericUrl && (
          <Button
            onClick={() => handleRemove()}
            disabled={disabled ?? false}
            icon="delete"
            type="danger"
          />
        )}
      </div>
    </>
  );
}
