import {
  FirstPageTypes,
  ThankYouPageTypes,
} from '../types/subscription-modal-form-common.types';

export enum MaxLengthFields {
  thankYouPageTitle = 61,
  thankYouPageSubtitle = 43,
  thankYouPagePromoCode = 18,
  thankYouPageCta = 22,
  firstPageTitle = 104,
  firstPageCta = 22,
}

const isValidURL = (url: string) => {
  const regex = /^(http|https):\/\/[^ "]+$/;
  return regex.test(url);
};

export const validateFields = (field: any) => {
  const newErrors: any = {};

  if (
    field.firstPageTitle === null ||
    field.firstPageTitle.length > MaxLengthFields.firstPageTitle
  ) {
    newErrors.firstPageTitle = `Title must be less than ${MaxLengthFields.firstPageTitle} characters`;
  }

  if (field.firstPageTitle === null || !field.firstPageTitle.length) {
    newErrors.firstPageTitle = `Title must be required`;
  }

  if (field.firstPageCta === null || !field.firstPageCta.length) {
    newErrors.firstPageCta = `CTA must be required`;
  }

  if (
    field.firstPageCta === null ||
    (field.firstPageCta &&
      field.firstPageCta.length > MaxLengthFields.firstPageCta)
  ) {
    newErrors.firstPageCta = `CTA must be less than ${MaxLengthFields.firstPageCta} characters`;
  }

  if (
    field.firstPageMediaDesktopType === FirstPageTypes.URL &&
    !isValidURL(field.firstPageMediaDesktop || '')
  ) {
    newErrors.firstPageMediaDesktop = 'Please enter a valid url';
  }

  if (
    field.firstPageMediaMobileType === FirstPageTypes.URL &&
    !isValidURL(field.firstPageMediaMobile || '')
  ) {
    newErrors.firstPageMediaMobile = 'Please enter a valid url';
  }

  return newErrors;
};

export const validateThankYouPageFields = (field: any) => {
  const newErrors: any = {};

  if (!field.thankYouPageType) {
    newErrors.thankYouPageType = 'Please select type';
  }

  if (
    field.thankYouPageTitle &&
    field.thankYouPageTitle.length > MaxLengthFields.thankYouPageTitle
  ) {
    newErrors.thankYouPageTitle = `Title must be less than ${MaxLengthFields.thankYouPageTitle} characters`;
  }

  if (!field.thankYouPageTitle.length || field.thankYouPageTitle === null) {
    newErrors.thankYouPageTitle = `Title must be required`;
  }

  if (
    field.thankYouPageSubtitle &&
    field.thankYouPageSubtitle.length > MaxLengthFields.thankYouPageSubtitle &&
    field.thankYouPageType === ThankYouPageTypes.PROMO_CONCERN
  ) {
    newErrors.thankYouPageSubtitle = `Subtitle must be less than ${MaxLengthFields.thankYouPageSubtitle} characters`;
  }

  if (
    !field.thankYouPageSubtitle.length &&
    field.thankYouPageType === ThankYouPageTypes.PROMO_CONCERN
  ) {
    newErrors.thankYouPageSubtitle = `Subtitle must be required`;
  }

  if (
    field.thankYouPagePromoCode &&
    field.thankYouPagePromoCode.length > MaxLengthFields.thankYouPagePromoCode
  ) {
    newErrors.thankYouPagePromoCode = `Promo code must be less than ${MaxLengthFields.thankYouPagePromoCode} characters`;
  }

  if (
    !field.thankYouPagePromoCode.length &&
    (field.thankYouPageType === ThankYouPageTypes.PROMO ||
      field.thankYouPageType === ThankYouPageTypes.PROMO_CONCERN)
  ) {
    newErrors.thankYouPagePromoCode = `Promo code must be required`;
  }

  if (
    field.thankYouPageCta &&
    field.thankYouPageCta.length > MaxLengthFields.thankYouPageCta &&
    (field.thankYouPageType === ThankYouPageTypes.CONCERN ||
      field.thankYouPageType === ThankYouPageTypes.PROMO_CONCERN)
  ) {
    newErrors.thankYouPageCta = `CTA must be less than ${MaxLengthFields.thankYouPageCta} characters`;
  }

  if (
    !field.thankYouPageCta.length &&
    (field.thankYouPageType === ThankYouPageTypes.CONCERN ||
      field.thankYouPageType === ThankYouPageTypes.PROMO_CONCERN)
  ) {
    newErrors.thankYouPageCta = `CTA must be less than required`;
  }

  return newErrors;
};

export const validateBlackListFields = (field: any) => {
  const newErrors: any = {};

  if (!field.matchType) {
    newErrors.matchType = 'Please select type';
  }

  if (!field.value) {
    newErrors.value = 'Please enter a value';
  }

  return newErrors;
};

export const validateDateField = ({
  initialDate,
  finalDate,
}: {
  initialDate: number | null | string;
  finalDate: number | null | string;
}) => {
  const newErrors: any = {};

  if (!initialDate || initialDate === 'Invalid date') {
    newErrors.initialDate = 'Please select initial date';
  }

  if (!finalDate || finalDate === 'Invalid date') {
    newErrors.finalDate = 'Please select final date';
  }

  if (initialDate! >= finalDate!) {
    newErrors.ErrorDate = 'The initial date must be before the final date';
  }

  return newErrors;
};

export const validateSubscriptionModalForm = (field: any) => {
  const newErrors: any = {};
  if (field.delayTime <= 0) {
    newErrors.delayTime = 'Delay time must be greater than 0';
  }

  return newErrors;
};
